// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// react router
import { Link, useLocation } from 'react-router';

// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Stack,
  useMediaQuery,
  Skeleton,
  Card,
  Alert,
  AlertTitle,
  Switch,
  Divider,
  Snackbar,
  Tooltip,
  IconButton,
  Box,
  InputAdornment,
  Link as MUILink,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers';

import { FiberManualRecord, ScheduleSend } from '@mui/icons-material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

// dayjs
import dayjs from 'dayjs';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { clearOtherData } from 'store/features/snackbarSlice';

import { useSnackbar } from 'notistack';

// project constants
import { affiliateBookingStatuses, bookingStatuses } from 'constants/bookings';
import userTypes from 'constants/userTypes';
import { managerLocationOptions } from 'constants/managerOptions';
import ManagerAccessRolePermissions from 'constants/managerAccessRolePermissions';
import AdministratorAccessRolePermissions from 'constants/administratorAccessRolePermissions';

// project utils
import { useGetEntityName } from 'utils/entities';
import {
  dateIsValidAtLocation,
  dayIsUnavailable,
  dayIsValidWorkday,
  getAvailableTimeSlots,
  getCreatedFrom,
  getDateWithCustomTime,
  getEarliestAvailableDate,
  getPreferredTimeFrameLabel,
  hasNoLunchTime
} from 'utils/booking';
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';
import callAzureFunctionPublic from 'utils/call-azure-function-public';
import companyLabelBuilder from 'utils/company';

// project components
import BookingLocationCollisionDialog from 'ui-component/dialogs/BookingLocationCollisionDialog';
import BookingsCollisionDialog from 'ui-component/dialogs/BookingsCollisionDialog';
import getSnackbarMessageType from 'utils/snackbar-message';
import HtmlTooltip from 'ui-component/tooltips/HtmlTooltip';
import AutocompleteWithInfiniteScroll from 'ui-component/autocompletes/AutocompleteWithInfiniteScroll';
import EmailBookingDialog from 'views/shared/manage-bookings/EmailBookingDialog';
import { CustomStaticDatePicker, CustomTimePicker } from 'ui-component/pickers/DateTimePickers';
import ChangeBookingTypeDialog from './ChangeBookingTypeDialog';
import ScheduledNotificationsDialog from 'views/shared/manage-bookings/dialogs/ScheduledNotificationsDialog';
import ActivityLogsDialog from './dialogs/ActivityLogsDialog';
import ManagerScheduleConflictDialog from 'ui-component/dialogs/ManagerScheduleConflictDialog';

const CustomTimeTextField = (params) => {
  const { InputProps } = params;
  return (
    <TextField
      {...params}
      variant="outlined"
      InputProps={{ ...InputProps, startAdornment: <InputAdornment position="start">Choose time</InputAdornment> }}
    />
  );
};

//= =============================|| ViewBookingDialog ||==============================//

const ViewBookingDialog = ({ open, setOpen, bookingId, getBookings, enableStatusUpdate, isReadOnly = false, mode }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const bookingEntityName = useGetEntityName('booking');
  const bookingsEntityName = useGetEntityName('bookings');
  const managerEntityName = useGetEntityName('manager');
  const respondentEntityName = useGetEntityName('respondent');
  const surveysEntityName = useGetEntityName('surveys');

  const appConfig = useSelector((state) => state.appConfig?.data);
  const bookingConfig = appConfig?.bookings;
  const user = useSelector((state) => state.user.data);
  const permissions = useSelector((state) => state.user.permissions);
  const canUpdateBookingCompanies = permissions.includes(AdministratorAccessRolePermissions.MANAGE_BOOKING_COMPANIES.key);
  const enableIinsightCaseNumQuery = appConfig?.integrations?.iinsight?.enableIinsightCaseNumQuery;
  const companiesFeatureEnabled = appConfig?.others?.companies;
  const iinsightCaseCreationEnabled = appConfig?.integrations.iinsight?.caseCreationConfig?.enabled;

  const pageLocation = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [booking, setBooking] = useState({});
  const [loadingBooking, setLoadingBooking] = useState(true);
  const [updatingBooking, setUpdatingBooking] = useState(false);
  const [viewEmailBookingDialogOpen, setViewEmailBookingDialogOpen] = useState(false);
  const [openChangeBookingTypeDialog, setOpenChangeBookingTypeDialog] = useState(false);

  const [managerHasGoogleCalendar, setManagerHasGoogleCalendar] = useState(false);
  const [managerHasOutlookCalendar, setManagerHasOutlookCalendar] = useState(false);

  const [openScheduledNotificationsDialog, setOpenScheduledNotificationsDialog] = useState(false);
  const [openActivityLogsDialog, setOpenActivityLogsDialog] = useState(false);

  const {
    Manager: manager,
    Respondent: respondent,
    Location: currentLocation,
    Service: currentService,
    Company: currentCompany,
    anyManagerAtLocation,
    respondentAvailabilityIsKnown,
    affiliateSelected,
    date,
    startHours,
    startMinutes,
    endHours,
    endMinutes,
    status,
    caseId,
    affiliateLocation,
    isSelectFromSystemAffiliateLocations,
    preferredTimeFrame,
    respondentConfirmationNotifications
  } = booking;
  const bookingCreatedFrom = useMemo(() => getCreatedFrom(booking), [booking]);

  // change status
  const [newStatus, setNewStatus] = useState(null);

  // change service
  const [changeService, setChangeService] = useState(false);
  const [newService, setNewService] = useState(null);
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [disableChangeService, setDisableChangeService] = useState(false);
  const service = newService || currentService;

  // change location
  const [changeLocation, setChangeLocation] = useState(false);
  const [newLocation, setNewLocation] = useState(null);
  const [disableChangeLocation, setDisableChangeLocation] = useState(false);

  // previous line before the system affiliate locations logic by Zenric
  // const location = newLocation || current location
  // eslint-disable-next-line prettier/prettier
  const location = isSelectFromSystemAffiliateLocations && affiliateSelected ? null : newLocation || currentLocation;

  // change manager
  const [changeManager, setChangeManager] = useState(false);
  const [newManagerOption, setNewManagerOption] = useState(null);
  const [newManager, setNewManager] = useState(null);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [loadingAvailableManagers, setLoadingAvailableManagers] = useState(false);
  const [disableChangeManager, setDisableChangeManager] = useState(false);
  // change date/time (reschedule)
  const [changeDateTime, setChangeDateTime] = useState(false);
  const [newDate, setNewDate] = useState(date ? dayjs(date) : dayjs());
  const [newTimeSlot, setNewTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [loadingTimeSlotsCount, setLoadingTimeSlotsCount] = useState(0);
  const startLoadingTimeSlots = () => setLoadingTimeSlotsCount((count) => count + 1);
  const stopLoadingTimeSlots = () => setLoadingTimeSlotsCount((count) => Math.max(count - 1, 0));

  const loadingTimeSlots = loadingTimeSlotsCount > 0;

  const [disableChangeDateTime, setDisableChangeDateTime] = useState(false);
  // change date/time (affiliate)
  const [changeDateTimeAffiliate, setChangeDateTimeAffiliate] = useState(false);
  const [oldTimeSlot, setOldTimeSlot] = useState(null);
  const defaultStartTime = dayjs().hour(7).minute(0).toDate();
  // change location (affiliate)
  const [newAffiliateLocation, setNewAffiliateLocation] = useState(affiliateLocation || '');
  const [newSystemAffiliateLocation, setNewSystemAffiliateLocation] = useState(null);
  const [isSpecifyAffiliateLoc, setIsSpecifyAffiliateLoc] = useState(false);

  // error
  const [error, setError] = useState(null);

  //
  const [isLoadingEmailEvent, setIsLoadingEmailEvent] = useState(false);
  const [latestEmailEvent, setLatestEmailEvent] = useState(null);
  const latestEmailEventTime = latestEmailEvent?.last_event_time ? dayjs(latestEmailEvent?.last_event_time) : null;

  // delete
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteTypedText, setDeleteTypedText] = useState('');
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [deleteSnackbarMessage, setDeleteSnackbarMessage] = useState('');
  const [deleteAssociatedSurvey, setDeleteAssociatedSurvey] = useState(false);

  // permissions
  const userCanUpdateBooking =
    user.type === userTypes.Admin ||
    ((user.type === userTypes.Manager || user.type === userTypes.ExternalUser) &&
      permissions.includes(ManagerAccessRolePermissions.BOOKING_WRITE.key));

  // Define your state for dialog
  const [collisionDialog, setCollisionDialog] = useState({
    open: false,
    title: '',
    content: null
  });

  const generateSlotFromStartValues = useCallback((service, date, startHours, startMinutes) => {
    const slot = {};
    slot.start = date ? dayjs(date).hour(startHours).minute(startMinutes).toDate() : dayjs().hour(7).minute(startMinutes).toDate();
    slot.end = dayjs(slot.start).add(service.duration, 'minute').toDate();
    return slot;
  }, []);

  useEffect(() => {
    if (affiliateSelected && service && !Number.isNaN(startHours) && !Number.isNaN(startMinutes)) {
      const slot = generateSlotFromStartValues(service, date, startHours, startMinutes);
      setNewTimeSlot(slot);
      setOldTimeSlot(slot);
    }
  }, [affiliateSelected, service, startHours, startMinutes, generateSlotFromStartValues, date]);

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteTypedText('');
    setDeleteConfirmationOpen(false);
    setDeleteAssociatedSurvey(false);
  };

  const isBookingSafe = async () => {
    if (!location || newStatus?.value !== bookingStatuses.Upcoming.value) {
      // booking with no location cannot have collision
      // booking that is updated to status other than upcoming cannot have collision
      return true;
    }

    let startDate;
    let endDate;
    if (!newTimeSlot) {
      const newSlot = generateSlotFromStartValues(service, date, startHours, startMinutes);
      startDate = dayjs(newSlot?.start);
      endDate = dayjs(newSlot?.end);
    } else {
      startDate = dayjs(newTimeSlot?.start);
      endDate = dayjs(newTimeSlot?.end);
    }

    // assuming start and end hours/minutes are integers
    let { workStartMinutes, workEndMinutes, lunchStartMinutes, lunchEndMinutes } = location;
    const { workStartHours, workEndHours, lunchStartHours, lunchEndHours } = location;

    // Convert times into minutes for easy comparison
    const startMinutesTotal = startDate.hour() * 60 + startDate.minute();
    const endMinutesTotal = endDate.hour() * 60 + endDate.minute();
    workStartMinutes = workStartHours * 60 + workStartMinutes;
    workEndMinutes = workEndHours * 60 + workEndMinutes;
    lunchStartMinutes = lunchStartHours * 60 + lunchStartMinutes;
    lunchEndMinutes = lunchEndHours * 60 + lunchEndMinutes;

    const locationHasLunchTime = !hasNoLunchTime(location);
    // Validate work hours and days
    if (
      startMinutesTotal < workStartMinutes ||
      endMinutesTotal > workEndMinutes ||
      !dayIsValidWorkday(startDate, location) ||
      dayIsUnavailable(startDate, location)
    ) {
      setCollisionDialog({
        open: true,
        title: 'Work Hours Collision',
        content: <BookingLocationCollisionDialog location={location} />
      });
      return false;
    }
    // Validate lunch break
    if (
      locationHasLunchTime &&
      ((startMinutesTotal >= lunchStartMinutes && startMinutesTotal < lunchEndMinutes) ||
        (endMinutesTotal > lunchStartMinutes && endMinutesTotal <= lunchEndMinutes) ||
        (startMinutes <= lunchStartMinutes && endMinutes >= lunchEndMinutes))
    ) {
      setCollisionDialog({
        open: true,
        title: 'Lunch Break Collision',
        content: <BookingLocationCollisionDialog location={location} />
      });
      return false;
    }

    const checkForCollision = async () => {
      try {
        await callAzureFunctionPublic({
          url: '/public/bookings-find-collision',
          method: 'get',
          params: {
            locationId: location?.id,
            startDate: dayjs(startDate).utc().format(), // Convert start date to UTC
            endDate: dayjs(endDate).utc().format(), // Convert end date to UTC
            bookingId
          }
        });
        return false;
      } catch (error) {
        const msg = handleError(error);
        return msg;
      }
    };

    if (newStatus?.value === bookingStatuses.Upcoming.value || (status === bookingStatuses.Upcoming.value && !newStatus?.value)) {
      const error = await checkForCollision();

      if (error) {
        setCollisionDialog({
          open: true,
          title: 'Booking Collision',
          content: <BookingsCollisionDialog bookings={[]} />
        });
        return false;
      }
    }

    return true;
  };

  const deleteBooking = useCallback(
    async (bookingId) => {
      try {
        setLoadingBooking(true);

        await callAzureFunction({
          url: `bookings`,
          method: 'delete',
          params: { bookingId, deleteAssociatedSurvey }
        });

        // don't reload on booking calendar as websocket message will cause reload instead
        // since getBookings is now an optional prop, getBookings() can only be executed if it is defined in the first place
        if (pageLocation?.pathname !== '/manage-booking-calendar' && Boolean(getBookings)) {
          getBookings();
        }
        setDeleteSnackbarMessage('Booking deleted');
        setDeleteSnackbarOpen(true);
      } catch (error) {
        const errorMessage = handleError(error);
        setDeleteSnackbarMessage(errorMessage);
        setDeleteSnackbarOpen(true);
      }
    },
    [deleteAssociatedSurvey, getBookings, pageLocation?.pathname]
  );

  const handleDelete = () => {
    deleteBooking(bookingId);
    closeSnackbar();
    setOpen(false);
    setDeleteConfirmationOpen(false);
    setDeleteTypedText(null);
    setDeleteAssociatedSurvey(false);
  };

  const formattedDate = useMemo(() => (date ? dayjs(date).format('DD/MM/YYYY') : null), [date]);
  const formattedTime = useMemo(() => {
    if (startHours === null || startMinutes === null || endHours === null || endMinutes === null) return 'TBD';

    const formattedStartTime = dayjs(getDateWithCustomTime(startHours, startMinutes)).format('hh:mm a');
    const formattedEndTime = dayjs(getDateWithCustomTime(endHours, endMinutes)).format('hh:mm a');

    return formattedStartTime && formattedEndTime ? `${formattedStartTime} - ${formattedEndTime}` : null;
  }, [startHours, startMinutes, endHours, endMinutes]);

  const bookingStatus = useMemo(() => {
    let bookingStatus;
    if (affiliateSelected) {
      bookingStatus = Object.values(affiliateBookingStatuses).find((bs) => bs.value === status);
    }
    if (!affiliateSelected || !bookingStatus) {
      bookingStatus = Object.values(bookingStatuses).find((bs) => bs.value === status);
    }
    return bookingStatus;
  }, [affiliateSelected, status]);

  const getStatusOptions = () => {
    if (affiliateSelected) {
      return Object.values(affiliateBookingStatuses).filter((each) => each.value);
    }
    return Object.values(bookingStatuses);
  };

  // Companies
  const [newCompany, setNewCompany] = useState(null);

  const shouldUpdateBTNRender = () => {
    const isChangeInTimeSlot = newTimeSlot && newTimeSlot?.start?.valueOf() !== oldTimeSlot?.start?.valueOf();
    const hasValuesChanged = Boolean(
      (newService && newService.id !== currentService.id) ||
        (newLocation && newLocation.id !== currentLocation.id) ||
        newManagerOption ||
        newManager ||
        isChangeInTimeSlot ||
        (affiliateSelected && newDate && dayjs(newDate).format('YYYY-MM-DD') !== dayjs(date).format('YYYY-MM-DD')) ||
        (newAffiliateLocation !== null && newAffiliateLocation !== undefined && newAffiliateLocation !== affiliateLocation) ||
        (newCompany && newCompany?.id !== currentCompany?.id) ||
        Boolean(Boolean(newStatus?.value) && newStatus?.value !== booking?.status)
    );

    // handles case where time picker has empty value or has invalid HH:MM:AMPM due to user input
    // eslint-disable-next-line no-restricted-globals
    if (affiliateSelected && isChangeInTimeSlot && (!newTimeSlot?.start || isNaN(newTimeSlot?.start?.valueOf()))) {
      return false;
    }

    if (enableStatusUpdate) {
      if (bookingStatus && newStatus && bookingStatus.value !== newStatus?.value) {
        return true;
      }
      if (newStatus) {
        return hasValuesChanged;
      }
    }

    return affiliateSelected && hasValuesChanged;
  };

  const isStatusFromAutocompletedToUpcoming = Boolean(
    status === bookingStatuses.Autocompleted.value && newStatus?.value.getTime === bookingStatuses.Upcoming.value
  );

  const canUpdateBooking = () => {
    if (isStatusFromAutocompletedToUpcoming) {
      // Allow updating of booking status from Autocompleted to Upcoming if the following conditions are met:
      // a) the change date time workflow has been selected as per changeDateTime boolean state

      // b) a new time slot has been changed
      const hasTimeSlotChanged = Boolean(newTimeSlot);

      return changeDateTime && hasTimeSlotChanged;
    }

    return true;
  };

  const getBooking = useCallback(async () => {
    try {
      setLoadingBooking(true);
      const response = await callAzureFunction({ url: `/bookings/${bookingId}`, method: 'get' });
      setBooking(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingBooking(false);
    }
  }, [bookingId]);

  const getLatestEmailEventLog = useCallback(async () => {
    try {
      setIsLoadingEmailEvent(true);
      const response = await callAzureFunction({ url: `/sendgrid/get-latest-email-event/${bookingId}`, method: 'get' });
      setLatestEmailEvent(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingEmailEvent(false);
    }
  }, [bookingId]);

  useEffect(() => {
    if (open && bookingId) {
      getBooking();
      getLatestEmailEventLog();
    }
  }, [open, bookingId, getBooking, getLatestEmailEventLog]);

  useEffect(() => {
    if (open) {
      setNewStatus(bookingStatus || null);
    }
  }, [open, bookingStatus]);

  const handleClose = () => {
    setLoadingBooking(true);
    dispatch(clearOtherData());
    // reset service
    setNewService(null);
    setChangeService(false);
    setDisableChangeService(false);
    // reset location
    setNewLocation(null);
    setNewAffiliateLocation(null);
    setChangeLocation(false);
    setDisableChangeLocation(false);
    setIsSpecifyAffiliateLoc(false);
    // reset manager
    setNewManager(null);
    setNewManagerOption(null);
    setChangeManager(false);
    setDisableChangeManager(false);
    // reset date time
    setNewDate(dayjs());
    setNewTimeSlot(null);
    setAvailableTimeSlots([]);
    setChangeDateTime(false);
    setChangeDateTimeAffiliate(false);
    setDisableChangeDateTime(false);
    // reset company
    setNewCompany(null);
    // error
    setError(null);
    // calendar event states
    setManagerHasGoogleCalendar(false);
    setManagerHasOutlookCalendar(false);
    if (updatingBooking) {
      setOpen(true);
      setLoadingBooking(false);
    } else {
      setOpen(false);
      setLoadingBooking(false);
    }
  };

  const getGoogleCalendar = async ({ subject }) => {
    if (!subject) {
      setManagerHasGoogleCalendar(false);
      return;
    }

    try {
      await callAzureFunction({
        method: 'get',
        url: 'google-calendar/calendars',
        params: { subject }
      });
      setManagerHasGoogleCalendar(true);
    } catch (error) {
      setManagerHasGoogleCalendar(false);
      handleError(error);
    }
  };

  const getOutlookCalendar = async ({ userPrincipalName }) => {
    if (!userPrincipalName) {
      setManagerHasOutlookCalendar(false);
      return;
    }

    try {
      const response = await callAzureFunction({
        method: 'get',
        url: 'outlook-calendar/calendars',
        params: { userPrincipalName }
      });

      const { canAccessOutlookCalendar } = response?.data || {};
      setManagerHasOutlookCalendar(canAccessOutlookCalendar);
    } catch (error) {
      setManagerHasOutlookCalendar(false);
      handleError(error);
    }
  };

  useEffect(() => {
    const selectedManager = changeManager ? newManager : manager;

    if (appConfig?.integrations?.googleCalendar?.calendarApiRead) getGoogleCalendar({ subject: selectedManager?.emailAddress });
    if (appConfig?.integrations?.outlookCalendar?.calendarApiRead) getOutlookCalendar({ userPrincipalName: selectedManager?.emailAddress });
  }, [manager, newManager, changeManager, appConfig, changeDateTime]);

  const getTimeSlots = useCallback(async () => {
    try {
      startLoadingTimeSlots();

      const selectedManagerOption = newManagerOption || Boolean(manager) ? managerLocationOptions.specific.value : null;
      const selectedManager = changeManager ? newManager || manager : manager;

      const timeSlots = await getAvailableTimeSlots({
        date: newDate,
        service,
        location,
        manager: selectedManager,
        managerHasGoogleCalendar,
        managerHasOutlookCalendar,
        managerOption: selectedManagerOption
      });

      setAvailableTimeSlots(timeSlots);
    } catch (error) {
      handleError(error);
    } finally {
      stopLoadingTimeSlots();
    }
  }, [
    service,
    newDate,
    location,
    manager,
    managerHasGoogleCalendar,
    managerHasOutlookCalendar,
    changeManager,
    newManager,
    newManagerOption
  ]);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots, newDate]);

  useEffect(() => {
    if (!affiliateSelected) setNewDate(getEarliestAvailableDate(location));
  }, [location, affiliateSelected]);

  useEffect(() => {
    if (changeDateTime) {
      setNewDate(booking.date ? dayjs(booking.date) : dayjs());
    }
  }, [changeDateTime, booking]);

  const getServices = useCallback(async () => {
    try {
      setLoadingServices(true);

      const response = await callAzureFunction({
        url: 'services-by-location',
        method: 'get',
        params: { locationId: location?.id }
      });
      const { rows } = response.data;

      await setServices(rows);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingServices(false);
    }
  }, [location?.id]);

  const getAvailableManagers = useCallback(async () => {
    try {
      setLoadingAvailableManagers(true);

      const response = await callAzureFunction({
        url: 'managers-by-location',
        method: 'get',
        params: { locationId: location.id }
      });
      const { rows } = response.data;

      // get managers that are available to selected service and location
      const availableManagers = rows.filter((locationManager) =>
        service?.Managers.find((serviceManager) => locationManager?.id === serviceManager?.id)
      );

      setAvailableManagers(availableManagers);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingAvailableManagers(false);
    }
  }, [location?.id, service?.Managers]);

  const handleLocationSwitch = (event) => {
    const { checked } = event.target;

    if (!respondentAvailabilityIsKnown) {
      setNewStatus(checked ? bookingStatuses.Upcoming : null);
    }

    setDisableChangeService(checked);
    setChangeService(checked);
    setDisableChangeManager(checked);
    setChangeManager(checked);
    setDisableChangeDateTime(checked);
    setChangeDateTime(checked);

    setNewService(null);
    setNewManagerOption(null);
    setNewManager(null);
    setNewDate(null);
    setNewTimeSlot(null);

    if (!checked) {
      setError(null);
      setNewLocation(null);
    }

    setChangeLocation(checked);
  };

  const handleServiceSwitch = (event) => {
    const { checked } = event.target;

    // if manually toggling service change, don't allow changing others
    if (respondentAvailabilityIsKnown) {
      setDisableChangeLocation(checked);
      setChangeLocation(false);
      setDisableChangeManager(checked);
      setChangeManager(false);
      setDisableChangeDateTime(checked);
      setChangeDateTime(false);

      setNewLocation(null);
      setNewManagerOption(null);
      setNewManager(null);
      setNewDate(null);
      setNewTimeSlot(null);
    } else {
      handleLocationSwitch(event);
    }

    if (!checked) {
      setError(null);
      setNewService(null);
    }

    setChangeService(checked);
  };

  const handleDateTimeSwitch = (event) => {
    const { checked } = event.target;

    if (checked) {
      setNewStatus(bookingStatuses.Upcoming);
    } else {
      setError(null);
      setNewTimeSlot(null);
    }

    setChangeDateTime(checked);
  };

  const handleManagerSwitch = (event) => {
    const { checked } = event.target;

    if (!checked) {
      setError(null);
      setNewManagerOption(null);
      setNewManager(null);
    }
    if (!respondentAvailabilityIsKnown) {
      handleDateTimeSwitch(event);
    }

    setChangeManager(checked);
  };

  const handleAffiliateDateTimeSwitch = (event) => {
    const { checked } = event.target;

    if (!checked) {
      setError(null);
      setNewDate(null);
      setNewTimeSlot(oldTimeSlot);
    }

    setChangeDateTimeAffiliate(checked);
  };

  const attemptUpdateBooking = async (options = { unsafe: false }) => {
    try {
      setError(null);
      setUpdatingBooking(true);
      const errors = [];

      // change service checks
      if (changeService && !newService) {
        errors.push(`Please select a service or deselect your request to change service`);
      }

      // change location checks
      if (changeLocation && !newLocation) {
        errors.push(`Please select a location or deselect your request to change location`);
      }
      // change manager checks
      if (changeManager) {
        if (!newManagerOption) {
          errors.push(`${managerEntityName} option is required for changing ${managerEntityName}`);
        }
        if (newManagerOption === 'specificManager' && !newManager) {
          errors.push(`Specific ${managerEntityName} is required for changing ${managerEntityName}`);
        }
      }
      // reschedule checks
      if (changeDateTime) {
        if (!newDate || !newTimeSlot) {
          errors.push('Date and Time are required for rescheduling');
        }
        if (newDate && (!dayIsValidWorkday(newDate, location) || dayIsUnavailable(newDate, location))) {
          errors.push('Location is not available on selected Date');
        }
        if (newDate && newTimeSlot && !dateIsValidAtLocation(newDate, newTimeSlot, location)) {
          errors.push('Selected Date/Time has already passed at the location');
        }
      }

      // status check
      if (!newStatus?.value) {
        errors.push(`${bookingEntityName} status is required.`);
      }

      if (errors.length) {
        setError(errors.join('. '));
      } else {
        setError(null);

        const data = {
          status: newStatus?.value || null,
          companyId: newCompany?.id,
          mode
        };
        if (options.unsafe) {
          data.updateUnsafeWithoutChecking = true;
        }
        if (changeService) {
          data.date = date;
          data.service = newService;
          data.startHours = startHours;
          data.startMinutes = startMinutes;
          const startTime = dayjs(date).hour(startHours).minute(startMinutes);
          data.endHours = dayjs(startTime).add(newService.duration, 'minute').hour();
          data.endMinutes = dayjs(startTime).add(newService.duration, 'minute').minute();
        }
        if (changeLocation) {
          data.location = newLocation;
        }
        if (changeManager) {
          data.managerOption = newManagerOption;
          data.manager = newManager;
        }

        // passed to get busy times of a manager as per Outlook calendar event
        data.timezone = changeLocation ? newLocation?.timezone : location?.timezone;

        if (changeDateTime) {
          // date and time
          data.date = dayjs(newDate).format('YYYY-MM-DD');
          data.startHours = dayjs(newTimeSlot.start).hour();
          data.startMinutes = dayjs(newTimeSlot.start).minute();
          data.endHours = dayjs(newTimeSlot.end).hour();
          data.endMinutes = dayjs(newTimeSlot.end).minute();
          data.respondentAvailabilityIsKnown = true;
          data.status = bookingStatuses.Upcoming.value;
        } else if (changeDateTimeAffiliate) {
          data.date = dayjs(newDate).format('YYYY-MM-DD');
          if (newTimeSlot) {
            data.startHours = dayjs(newTimeSlot.start).hour();
            data.startMinutes = dayjs(newTimeSlot.start).minute();
            data.endHours = dayjs(newTimeSlot.end).hour();
            data.endMinutes = dayjs(newTimeSlot.end).minute();
          } else if (!oldTimeSlot) {
            data.startHours = dayjs(defaultStartTime).hour();
            data.startMinutes = dayjs(defaultStartTime).minute();
            data.endHours = dayjs(defaultStartTime).add(service.duration, 'minute').hour();
            data.endMinutes = dayjs(defaultStartTime).add(service.duration, 'minute').minute();
          }
          data.respondentAvailabilityIsKnown = true;
        }
        if (newAffiliateLocation !== null && newAffiliateLocation !== undefined && isSpecifyAffiliateLoc && affiliateSelected) {
          data.affiliateLocation = newAffiliateLocation;
          data.isSelectFromSystemAffiliateLocations = false;
          data.location = null;
        } else if (newSystemAffiliateLocation !== null && !isSpecifyAffiliateLoc && affiliateSelected) {
          data.location = newSystemAffiliateLocation;
          data.isSelectFromSystemAffiliateLocations = true;
          data.affiliateLocation = null;
        }

        let updateUrl;
        if (user.type === userTypes.Admin) {
          updateUrl = `/bookings/${booking.id}`;
        } else if (user.type === userTypes.Manager || user.type === userTypes.ExternalUser) {
          updateUrl = `/bookings/${booking.id}/as-manager`;
        }

        const response = await callAzureFunction({ url: updateUrl, method: 'put', data });

        const targetStatus = [bookingStatuses.Canceled.value, bookingStatuses.DidNotAttend.value, affiliateBookingStatuses.Confirmed.value];
        const changeInStatus = targetStatus.includes(newStatus?.value);

        const shouldBookingActionSnackbarAppear = changeLocation || changeService || changeDateTime || changeInStatus;

        if (shouldBookingActionSnackbarAppear) {
          const locationChanged = Boolean(currentLocation?.id !== newLocation?.id && changeLocation);
          const serviceChanged = Boolean(currentService?.id !== newService?.id && changeService);

          const messageType = getSnackbarMessageType({
            locationChanged,
            serviceChanged,
            changeInDateTime: changeDateTime,
            changeInStatus,
            status: newStatus?.value
          });

          enqueueSnackbar({
            variant: 'BOOKING_ACTION',
            autoHideDuration: 20000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            variantProps: {
              bookingId: booking.id,
              respondentId: booking.respondentId,
              messageType
            }
          });
        }

        const { caseCreationAttempted, caseCreated, caseCreationError } = response.data;

        if (caseCreationAttempted) {
          let message;
          let variant;

          if (caseCreated) {
            variant = 'success';
            message = 'A case has been successfully created in Iinsight';
          } else {
            variant = 'error';
            message = `Failed attempt to create a case in Iinsight. Error info: ${caseCreationError}`;
          }

          enqueueSnackbar({
            variant,
            message: <Typography sx={{ maxWidth: 500 }}>{message}</Typography>,
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
          });
        }

        handleClose();
        // don't reload booking on manage booking calendar, as websocket message will cause reload here already
        if (pageLocation?.pathname !== '/manage-booking-calendar' || enableStatusUpdate === false) {
          getBookings();
        }
      }
    } catch (error) {
      setError(handleError(error));

      const { isManagerNotAvailable } = error.response?.data?.data || {};
      if (isManagerNotAvailable) {
        setCollisionDialog({
          open: true,
          title: 'Conflict with Manager Schedule',
          content: <ManagerScheduleConflictDialog />
        });
        return;
      }
    } finally {
      setUpdatingBooking(false);
    }
  };

  const handleUpdateBookingButton = async () => {
    const bookingIsSafe = await isBookingSafe();
    if (bookingIsSafe) {
      attemptUpdateBooking({ unsafe: false });
    }
  };

  const handleSendReminder = async () => {
    setOpen(false);
    setViewEmailBookingDialogOpen(true);
  };

  const handleOpenChangeBookingTypeDialog = () => {
    setOpen(false);
    setOpenChangeBookingTypeDialog(true);
  };

  useEffect(() => {
    // get new services whenever location is changed
    // this is smoother for updating service selections automatically than previous
    // which waited for user to click on the services dropdown
    if (location?.id) {
      getServices();
    }
  }, [getServices, location?.id, newLocation]);

  const setNewServiceHelper = (newService) => {
    if (changeLocation) {
      setNewLocation(null);
      setNewManagerOption(null);
      setNewManager(null);
    }
    setNewService(newService);
  };

  const setNewLocationHelper = (newLocation) => {
    if (changeLocation) {
      setNewManagerOption(null);
      setNewManager(null);
    }
    setNewLocation(newLocation);
  };

  const renderLocationDetails = () => {
    if (user.type === userTypes.Admin && !affiliateSelected) {
      return currentLocation ? (
        <Link to={`/manage-locations/edit-location/${currentLocation.id}`} style={{ marginLeft: '4px' }}>
          {currentLocation.name}
        </Link>
      ) : (
        '-'
      );
    }

    if (currentLocation) {
      return currentLocation.deletedAt ? (
        <Tooltip title="This location is currently archived in the system." placement="right">
          <Typography sx={{ ml: 0.5 }}>{`${currentLocation.name} (Archived)`}</Typography>
        </Tooltip>
      ) : (
        <Typography sx={{ ml: 0.5 }}>{currentLocation.name}</Typography>
      );
    }

    return '-';
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        {loadingBooking ? (
          <DialogContent>
            <Skeleton variant="rectangular" height={250} />
          </DialogContent>
        ) : (
          <>
            {booking.id ? (
              <>
                <DialogTitle variant="h3">
                  {bookingEntityName} for {respondent ? `${respondent.firstName} ${respondent.lastName}` : '-'}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} mt={2}>
                    {/* status */}
                    <Grid container item xs={6} direction="row">
                      {userCanUpdateBooking && enableStatusUpdate ? (
                        <Grid container item alignItems="center">
                          <Typography>Status: </Typography>
                          <Autocomplete
                            id="select-new-status"
                            options={getStatusOptions()}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            getOptionLabel={(option) => (option ? option.label : '')}
                            value={newStatus}
                            onChange={(_, value) => setNewStatus(value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: newStatus ? <FiberManualRecord style={{ color: newStatus.color, fontSize: 18 }} /> : null
                                }}
                              />
                            )}
                            sx={{ maxWidth: 200 }}
                            size="small"
                            fullWidth
                            renderOption={(props, option) => (
                              <li {...props}>
                                <FiberManualRecord style={{ color: option.color, fontSize: 18, marginRight: '10px' }} />
                                {option.label}
                              </li>
                            )}
                            style={{ marginLeft: '5px' }}
                          />
                        </Grid>
                      ) : (
                        <Typography>
                          Status: {bookingStatus ? <span style={{ color: bookingStatus.color }}>{bookingStatus.label}</span> : '-'}
                        </Typography>
                      )}
                    </Grid>

                    {bookingConfig?.allowAffiliate && user?.type === userTypes.Admin && (
                      <Grid item xs={12} sm={6} mt={2}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Button onClick={handleOpenChangeBookingTypeDialog} size="small">
                            Change to {affiliateSelected ? 'Non-affiliate' : 'affiliate'}
                          </Button>
                          <Tooltip
                            title={
                              affiliateSelected
                                ? `Non-affiliate ${bookingsEntityName} are regular ${bookingsEntityName} that have a location and ${managerEntityName} from your AtlasOne system`
                                : `Affiliate ${bookingsEntityName} are ${bookingsEntityName} outside of your normal locations. It has no ${managerEntityName}, and the location does not have to exist in your AtlasOne system`
                            }
                            placement="top"
                          >
                            <InfoIcon sx={{ fontSize: 18 }} color="primary" />
                          </Tooltip>
                        </Stack>
                      </Grid>
                    )}

                    {/* respondent */}
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        {respondentEntityName}:
                        {respondent ? (
                          <Link
                            to={`/respondents/view-respondent/${respondent.id}`}
                            style={{ marginLeft: '4px' }}
                          >{`${respondent.firstName} ${respondent.lastName}`}</Link>
                        ) : (
                          ' -'
                        )}
                      </Typography>
                    </Grid>

                    {/* manager */}
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        {managerEntityName}:
                        {anyManagerAtLocation
                          ? ` Any ${managerEntityName} at Location`
                          : manager && (
                              <>
                                {user.type === userTypes.Admin ? (
                                  <Link to={`/manage-managers/edit-account/${manager.id}`} style={{ marginLeft: '4px' }}>
                                    {`${manager.firstName} ${manager.lastName}`}
                                  </Link>
                                ) : (
                                  ` ${manager.firstName} ${manager.lastName}`
                                )}
                              </>
                            )}
                        {!anyManagerAtLocation && !manager && ' -'}
                      </Typography>
                    </Grid>

                    {/* location */}
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        Location:
                        <>
                          {user.type === userTypes.Admin && affiliateSelected && (
                            <Stack gap={1}>
                              {!isSpecifyAffiliateLoc ? (
                                <AutocompleteWithInfiniteScroll
                                  sx={{ width: 180, ml: 1 }}
                                  size="small"
                                  // custom props
                                  optionsUrl="locations/autocomplete?isAffiliate=true"
                                  optionsHighlight
                                  // autocomplete props
                                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                  getOptionLabel={(option) => option?.name || ''}
                                  value={currentLocation}
                                  onChange={(event, value) => {
                                    setNewSystemAffiliateLocation(value);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                  fullWidth
                                  style={{ maxWidth: '100%' }}
                                />
                              ) : (
                                <TextField
                                  size="small"
                                  style={{ marginLeft: '4px' }}
                                  value={newAffiliateLocation || affiliateLocation || ''}
                                  onChange={(e) => setNewAffiliateLocation(e.target.value)}
                                />
                              )}
                            </Stack>
                          )}
                          {affiliateSelected && (
                            <Typography
                              onClick={() => setIsSpecifyAffiliateLoc(!isSpecifyAffiliateLoc)}
                              variant="caption"
                              sx={{ ml: 1, textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              {isSpecifyAffiliateLoc ? 'Show selected' : 'Show specified'}
                            </Typography>
                          )}
                          {renderLocationDetails()}
                        </>
                      </Typography>
                    </Grid>

                    {/* service */}
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        Service:
                        {currentService ? (
                          <>
                            {user.type === userTypes.Admin ? (
                              <>
                                {currentService?.deletedAt ? (
                                  <Tooltip title="This service is currently archived in the system." placement="right">
                                    <Typography sx={{ ml: 0.5 }}>{currentService.name} (Archived)</Typography>
                                  </Tooltip>
                                ) : (
                                  <Link
                                    to={`/manage-services/edit-service/${currentService.id}`}
                                    style={{ marginLeft: '4px' }}
                                  >{`${currentService.name}`}</Link>
                                )}
                              </>
                            ) : (
                              <>
                                {currentService?.deletedAt ? (
                                  <Tooltip title="This service is currently archived in the system." placement="right">
                                    <Typography sx={{ ml: 0.5 }}>{currentService.name} (Archived)</Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography sx={{ ml: 0.5 }}>{currentService.name}</Typography>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ' - '
                        )}
                      </Typography>
                    </Grid>

                    {/* date */}
                    <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                      <Typography>Date: {respondentAvailabilityIsKnown ? formattedDate : '-'}</Typography>
                    </Grid>

                    {/* time */}
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        Appointment Time:{' '}
                        {respondentAvailabilityIsKnown && !preferredTimeFrame ? (
                          formattedTime
                        ) : (
                          <>
                            {respondentConfirmationNotifications ? (
                              <MUILink
                                sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 1 }}
                                onClick={() => setOpenScheduledNotificationsDialog(true)}
                              >
                                Notification Schedule <ScheduleSend sx={{ verticalAlign: 'middle' }} />
                              </MUILink>
                            ) : (
                              <>-</>
                            )}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    {preferredTimeFrame && (
                      <Grid item xs={12} sm={6}>
                        <Typography textTransform="capitalize">
                          Preferred Time Frame: {getPreferredTimeFrameLabel(preferredTimeFrame)}
                        </Typography>
                      </Grid>
                    )}

                    {/* bookingId */}
                    {bookingId && (
                      <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                        <Typography>AtlasOne Booking No: {bookingId}</Typography>
                      </Grid>
                    )}

                    {/* caseId */}
                    {(enableIinsightCaseNumQuery || iinsightCaseCreationEnabled) && (
                      <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                        <Typography>iinsight Case Number: {caseId || 'None'}</Typography>
                      </Grid>
                    )}

                    {/* companies */}
                    {companiesFeatureEnabled &&
                      (user.type === userTypes.Admin && canUpdateBookingCompanies ? (
                        <Grid container item xs={6} direction="row" alignItems="center" wrap="nowrap">
                          <Typography>Company: </Typography>
                          <AutocompleteWithInfiniteScroll
                            // custom props
                            optionsUrl="companies/autocomplete"
                            optionsHighlight
                            // autocomplete props
                            id="company-select"
                            // allows newCompany to be an empty string but not null
                            value={newCompany !== undefined && newCompany !== null ? newCompany : currentCompany}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => companyLabelBuilder(option)}
                            onChange={(_, value) => setNewCompany(value)}
                            renderInput={(params) => <TextField {...params} />}
                            size="small"
                            fullWidth
                            style={{ marginLeft: '5px', marginRight: '50px', minWidth: '170px' }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                          <Typography>Company: {currentCompany?.name}</Typography>
                        </Grid>
                      ))}

                    {/* created from */}
                    {bookingCreatedFrom && (
                      <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                        <Typography>Created From: {bookingCreatedFrom}</Typography>
                      </Grid>
                    )}

                    {/* Email Notification */}
                    <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                      {!isLoadingEmailEvent ? (
                        <Typography>
                          Notification:{' '}
                          {latestEmailEvent
                            ? `Last email sent on ${latestEmailEventTime?.format('MMMM D, YYYY')} at ${latestEmailEventTime.format('hh:mm A')} to ${latestEmailEvent.to_email}`
                            : 'No data available'}
                          <Tooltip
                            title={
                              <>
                                {latestEmailEvent
                                  ? `This log pertains to the latest email sent to the ${respondentEntityName?.toLowerCase()}
                                's primary email address. Refers to the email subject - ${latestEmailEvent.subject}`
                                  : `This indicates that either no
                                email has been sent to the ${respondentEntityName?.toLowerCase()}, or the email log has expired. Email
                                logs are only retained for up to 30 days.`}
                                <br />
                                <br />
                                <span>
                                  If you have recently sent an email through the system or are expecting a system reminder email, it may
                                  take a few minutes for the log to update.
                                </span>
                              </>
                            }
                            placement="top"
                          >
                            <InfoIcon sx={{ verticalAlign: 'middle', fontSize: 20, mb: 0.5, ml: 1 }} />{' '}
                          </Tooltip>
                        </Typography>
                      ) : (
                        <>
                          <Grid direction="row" item xs={12}>
                            <Grid container direction="row" alignItems="center">
                              <Grid item>
                                <Typography display="inline" mr={1}>
                                  Email Notification:{' '}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Skeleton width={205} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Skeleton width={335} />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} alignItems="center" sx={{ display: 'flex' }}>
                      <Typography display="inline" mr={1} sx={{ textTransform: latestEmailEvent?.status ? 'capitalize' : 'none' }}>
                        Email Status: {!isLoadingEmailEvent && <>{latestEmailEvent?.status?.replace('_', ' ') || 'No data available'}</>}{' '}
                      </Typography>
                      {isLoadingEmailEvent && <Skeleton width={150} ml={2} />}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    {!isReadOnly && (
                      <>
                        {userCanUpdateBooking && (
                          <Grid container item xs={12}>
                            {/* change service */}
                            <Grid item xs={12} sm={3}>
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <Typography>Select Service?</Typography>
                                <Switch checked={changeService} onChange={handleServiceSwitch} disabled={disableChangeService} />
                              </Stack>
                            </Grid>
                            {/* change location */}
                            {!affiliateSelected && (
                              <Grid item xs={12} sm={3}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  <Typography>Select Location?</Typography>
                                  <Switch checked={changeLocation} onChange={handleLocationSwitch} disabled={disableChangeLocation} />
                                </Stack>
                              </Grid>
                            )}
                            {/* change manager */}
                            {!affiliateSelected && (
                              <Grid item xs={12} sm={3}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  <Typography>Select {managerEntityName}?</Typography>
                                  <Switch checked={changeManager} onChange={handleManagerSwitch} disabled={disableChangeManager} />
                                </Stack>
                              </Grid>
                            )}
                            {/* change date/time */}
                            {affiliateSelected ? (
                              <Grid item xs={12} sm={3}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Typography>Select Date & Time?</Typography>
                                  <Switch checked={changeDateTimeAffiliate} onChange={handleAffiliateDateTimeSwitch} />
                                </Stack>
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={3}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  <Typography>Select Date & Time?</Typography>
                                  <Switch checked={changeDateTime} onChange={handleDateTimeSwitch} disabled={disableChangeDateTime} />
                                </Stack>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </>
                    )}

                    {/* service options for change service */}
                    {changeService && (
                      <Grid item xs={12}>
                        <Divider />
                        <Autocomplete
                          id="select-service"
                          loading={loadingServices}
                          options={services}
                          isOptionEqualToValue={(option, value) => option?.id === value.id}
                          getOptionLabel={(option) => (option?.id ? `${option.name}` : '')}
                          value={newService}
                          onChange={(_, value) => setNewServiceHelper(value)}
                          renderInput={(params) => <TextField {...params} label="Service" />}
                          fullWidth
                        />
                      </Grid>
                    )}

                    {/* location options for change location */}
                    {!affiliateSelected && changeLocation && (
                      <Grid item xs={12}>
                        <Divider />
                        <AutocompleteWithInfiniteScroll
                          // custom props
                          optionsUrl="locations/autocomplete"
                          optionsHighlight
                          // autocomplete props
                          id="select-location"
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          getOptionLabel={(option) => (option?.id ? `${option.name}` : '')}
                          value={newLocation}
                          onChange={(_, value) => setNewLocationHelper(value)}
                          renderInput={(params) => <TextField {...params} label="Location" />}
                          fullWidth
                        />
                      </Grid>
                    )}

                    {/* manager options for change manager */}
                    {!affiliateSelected && changeManager && (
                      <Grid item xs={12}>
                        <Divider />
                        <Grid container spacing={1} mb={2} mt={2}>
                          {Object.values(managerLocationOptions).map((managerOption) => (
                            <Grid item key={managerOption.value} xs={12} sm={6}>
                              <Card
                                variant="outlined"
                                sx={{
                                  p: 2,
                                  cursor: 'pointer',
                                  borderColor: newManagerOption === managerOption.value && theme.palette.primary.main,
                                  borderWidth: newManagerOption === managerOption.value && 3
                                }}
                                onClick={() => setNewManagerOption(managerOption.value)}
                              >
                                <Typography variant="h3">{managerOption.getLabel(managerEntityName)}</Typography>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>

                        {/* select manager from available managers */}
                        {newManagerOption === 'specificManager' && (
                          <Autocomplete
                            id="select-manager"
                            loading={loadingAvailableManagers}
                            options={availableManagers}
                            isOptionEqualToValue={(option, value) => option?.id === value.id}
                            getOptionLabel={(option) => (option?.id ? `${option.firstName} ${option.lastName}` : '')}
                            value={newManager}
                            onChange={(_, value) => setNewManager(value)}
                            onOpen={getAvailableManagers}
                            renderInput={(params) => <TextField {...params} label={`Select ${managerEntityName}`} />}
                            fullWidth
                            disabled={!location?.id}
                          />
                        )}
                      </Grid>
                    )}

                    {/* date and time picker for rescheduling */}
                    {!affiliateSelected && changeDateTime && (
                      <Grid item xs={12}>
                        <Divider />
                        <Grid container spacing={3}>
                          {/* date picker */}
                          <Grid item xs={12} sm={12} md={6}>
                            <CustomStaticDatePicker
                              value={newDate}
                              onChange={(value) => setNewDate(value)}
                              slotProps={{ actionBar: { actions: [] } }}
                              disablePast
                              shouldDisableDate={(value) => !dayIsValidWorkday(value, location) || dayIsUnavailable(value, location)}
                            />
                          </Grid>
                          {/* time (slot) picker */}
                          <Grid item xs={12} sm={12} md={6} direction="column" mt={matchesDownMd ? 0 : 12}>
                            <Grid item xs={6}>
                              {newManagerOption !== managerLocationOptions.any.value && (
                                <Stack direction="row" sx={{ display: 'absolute', mt: -6, mb: 2 }}>
                                  {managerHasGoogleCalendar && (
                                    <HtmlTooltip
                                      disableFocusListener
                                      title={`Google Calendar events from the selected ${managerEntityName} are being considered for the available time slots.`}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <GoogleIcon />
                                      </IconButton>
                                    </HtmlTooltip>
                                  )}
                                  {managerHasOutlookCalendar && (
                                    <HtmlTooltip
                                      disableFocusListener
                                      title={`Outlook Calendar events from the selected ${managerEntityName} are being considered for the available time slots.`}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <MicrosoftIcon />
                                      </IconButton>
                                    </HtmlTooltip>
                                  )}
                                  <Box sx={{ height: 40 }} />
                                </Stack>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              {/* time slots */}
                              {loadingTimeSlots ? (
                                <Skeleton variant="rectangular" height={250} />
                              ) : (
                                <>
                                  {availableTimeSlots.length > 0 ? (
                                    <Grid container spacing={1} sx={{ maxHeight: 190, overflow: 'auto' }}>
                                      {availableTimeSlots.map((slot) => (
                                        <Grid item key={slot.startText} xs={12} sm={6} md={4}>
                                          <Card
                                            variant="outlined"
                                            sx={{
                                              p: 2,
                                              cursor: 'pointer',
                                              textAlign: 'center',
                                              borderColor: newTimeSlot?.startText === slot.startText && theme.palette.primary.main,
                                              borderWidth: newTimeSlot?.startText === slot.startText && 3
                                            }}
                                            onClick={() => setNewTimeSlot(slot)}
                                          >
                                            <Typography variant="h5">{slot.startText}</Typography>
                                          </Card>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  ) : (
                                    <Typography>No available time slots.</Typography>
                                  )}
                                  {/* custom time picker */}
                                  <Stack sx={{ mt: 3 }}>
                                    <TimePicker
                                      value={newTimeSlot?.start ? dayjs(newTimeSlot.start) : null}
                                      onChange={(value) => {
                                        if (value) {
                                          const start = dayjs(value);
                                          const end = start.add(service.duration, 'minute');
                                          const timeSlot = {
                                            start,
                                            startText: start.format('hh:mm a'),
                                            end,
                                            endText: end.format('hh:mm a')
                                          };
                                          setNewTimeSlot(timeSlot);
                                        } else {
                                          setNewTimeSlot(null);
                                        }
                                      }}
                                      slots={{ textField: CustomTimeTextField }}
                                    />
                                  </Stack>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* date and time picker for affiliate */}
                    {affiliateSelected && changeDateTimeAffiliate && (
                      <Grid container item xs={12}>
                        {/* date picker */}
                        <Grid item xs={6}>
                          <CustomStaticDatePicker
                            value={newDate}
                            onChange={(value) => setNewDate(value)}
                            slotProps={{ actionBar: { actions: [] } }}
                          />
                        </Grid>

                        {/* time picker */}
                        <Grid item xs={6} style={{ paddingTop: '16px' }}>
                          <Typography
                            style={{
                              color: '#9e9e9e',
                              lineHeight: 2.66,
                              fontSize: '0.75rem'
                            }}
                          >
                            SELECT START TIME
                          </Typography>

                          <CustomTimePicker
                            value={dayjs(newTimeSlot?.start)}
                            onChange={(newValue) => {
                              setNewTimeSlot({
                                start: newValue,
                                end: dayjs(newValue).add(service.duration, 'minute').toDate()
                              });
                            }}
                            label="Time"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {error && (
                    <Grid item xs={12} mt={1}>
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                      </Alert>
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenActivityLogsDialog(true)}>Activity Logs</Button>
                  {/* delete booking */}
                  {user.type === userTypes.Admin && !isReadOnly && (
                    <Button color="error" onClick={handleDeleteConfirmation}>
                      Delete
                    </Button>
                  )}
                  {/* update booking */}
                  {(user.type === userTypes.Admin ||
                    ((user.type === userTypes.Manager || user.type === userTypes.ExternalUser) &&
                      permissions.includes(ManagerAccessRolePermissions.BOOKING_WRITE.key))) &&
                    shouldUpdateBTNRender() && (
                      <Tooltip
                        title="Change into a valid schedule to proceed with the updating"
                        disableHoverListener={canUpdateBooking()}
                        placement="top"
                      >
                        <span>
                          <LoadingButton loading={updatingBooking} onClick={handleUpdateBookingButton} disabled={!canUpdateBooking()}>
                            <span>Update {bookingEntityName}</span>
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    )}
                  {/* send booking reminder */}
                  {user.type === userTypes.Admin && bookingStatus?.value === bookingStatuses.Upcoming.value && (
                    <LoadingButton onClick={handleSendReminder}>
                      <span>Send Notification</span>
                    </LoadingButton>
                  )}
                  {/* close dialog */}
                  <Button variant="contained" onClick={handleClose} disabled={updatingBooking}>
                    Close
                  </Button>
                </DialogActions>
              </>
            ) : (
              <Typography>{bookingEntityName} not found</Typography>
            )}
          </>
        )}
      </Dialog>

      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <DialogTitle>
          <Typography variant="h3">Confirm Delete</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5">
            Type &quot;<strong>DELETE</strong>&quot; to confirm deletion.
          </Typography>
          <TextField
            autoFocus
            type="text"
            fullWidth
            value={deleteTypedText}
            onChange={(event) => setDeleteTypedText(event.target.value.toUpperCase())}
            style={{ paddingTop: '5px' }}
          />
          <FormControlLabel
            control={<Checkbox checked={deleteAssociatedSurvey} onChange={(e) => setDeleteAssociatedSurvey(e.target.checked)} />}
            label={`Also delete any ${surveysEntityName} that are associated with this ${bookingEntityName}?`}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteTypedText !== 'DELETE'} onClick={handleDelete}>
            Confirm Delete
          </Button>
          <Button variant="contained" onClick={handleCancelDelete}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={deleteSnackbarOpen}
        onClose={() => {
          setDeleteSnackbarOpen(false);
        }}
        message={deleteSnackbarMessage}
        autoHideDuration={3000}
      />

      <Dialog
        open={collisionDialog.open}
        onClose={() => {
          setCollisionDialog({ open: false, title: '', content: '' });
        }}
        maxWidth="xs"
      >
        <DialogTitle>{collisionDialog.title}</DialogTitle>
        <DialogContent>{collisionDialog.content}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCollisionDialog({ open: false, title: '', content: '' });
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCollisionDialog({ open: false, title: '', content: '' });
              attemptUpdateBooking({ unsafe: true });
            }}
          >
            Continue Anyway
          </Button>
        </DialogActions>
      </Dialog>
      <EmailBookingDialog
        setViewDialogOpen={setOpen}
        setOpen={setViewEmailBookingDialogOpen}
        open={viewEmailBookingDialogOpen}
        bookingId={bookingId}
      />
      <ChangeBookingTypeDialog
        open={openChangeBookingTypeDialog}
        setOpen={setOpenChangeBookingTypeDialog}
        onClosed={() => setOpen(true)}
        booking={booking}
        onUpdated={() => {
          setOpen(false);
          getBookings();
        }}
      />
      <ScheduledNotificationsDialog
        open={openScheduledNotificationsDialog}
        setOpen={setOpenScheduledNotificationsDialog}
        bookingId={bookingId}
      />

      <ActivityLogsDialog open={openActivityLogsDialog} setOpen={setOpenActivityLogsDialog} bookingId={bookingId} />
    </>
  );
};

ViewBookingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  bookingId: PropTypes.string.isRequired,
  getBookings: PropTypes.func, // optional as BookingActionSnackbar will not pass a getBookings prop when using this dialog
  enableStatusUpdate: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool, // passed as true if the ViewBookingDialog has originated from the BookingActionSnackbar (notistack)
  mode: PropTypes.string // calendar mode, passed through from calendar
};

export default ViewBookingDialog;
